import consumer from "../consumer";
import { post } from "@rails/request.js";

consumer.subscriptions.create("Memberships::NotificationChannel", {
  received(data) {
    post(
      `/account/memberships/${data["membership_id"]}/notify?${data["type"]}=${data["message"]}`,
      { responseKind: "turbo-stream" }
    );
  },
});
