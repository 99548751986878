import { Controller } from "@hotwired/stimulus";
import createTableFlowImporter from "@tableflow/js";

export default class extends Controller {
  static values = {
    importerId: String,
    metadata: { default: {}, type: Object },
  };

  connect() {
    if (!this.hasImporterIdValue) throw new Error("missing importerId value");
    this.importer = this.createImporter();
  }

  showModal() {
    this.importer?.showModal();
  }

  createImporter() {
    if (!this.hasImporterIdValue) return;

    return createTableFlowImporter({
      importerId: this.importerIdValue,
      darkMode:
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches,
      metadata: this.metadataValue, // Pass through user or import info
      modalOnCloseTriggered: () => this.importer?.close(),
      onComplete:  (data) => console.log(data),
    });
  }
}
