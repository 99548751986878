import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="routers--rules--post-processing--form"
export default class extends Controller {
  static values = { field: String, value: String };
  connect() {
    this.form = this.element;
  }

  setFieldValue({ detail: { event } }) {
    this.fieldValue = $(event.target).select2("data")[0].id;
    this.submitForm();
  }

  setValueValue({ detail: { event } }) {
    this.valueValue = $(event.target).select2("data")[0].id;
    this.submitForm();
  }

  submitForm() {
    if (!this.hasFieldValue || !this.fieldValue || !this.form) return;
    this.form.requestSubmit();
  }
}
