import CRMLinkController from "../../../xkit/crm_link_controller";

export default class extends CRMLinkController {
  async confirmTrackers() {
    document.querySelector("#confirm_trackers_form").requestSubmit();
  }

  async show(event) {
    const customEvent = new CustomEvent(event.type, {
      detail: { event, afterFinish: this.confirmTrackers },
    });
    await super.show(customEvent);
  }
}
