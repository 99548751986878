import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

export default class extends Controller {
  static values = { crmTriggerId: String, active: Boolean };

  async handleToggle({ detail: { switch_state } }) {
    const response = await put(
      `/account/crm_triggers/${this.crmTriggerIdValue}`,
      {
        body: JSON.stringify({
          crm_trigger: { active: switch_state },
        }),
        responseKind: "turbo-stream",
      }
    );
    if (!response.ok) {
      const error = await response.json;
      console.error(error);
    }
  }
}
