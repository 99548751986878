import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { debounce } from "lodash";
import Fuse from "fuse.js";
import "ninja-keys";

export default class extends Controller {
  static values = {
    userId: String,
    membershipId: String,
    teamId: String,
    roles: Array,
  };

  addListeners() {
    this.addThemeListener();
    this.addChangeListener();
    this.addVisibilityListener();
  }

  addThemeListener() {
    const self = this;

    const darkModePreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    darkModePreference.addEventListener("change", (e) =>
      self.setDarkTheme(e.matches)
    );
  }

  addChangeListener() {
    const self = this;

    this.element.addEventListener(
      "change",
      debounce(this.handleCommandPaletteChange.bind(self), 200)
    );
  }

  addVisibilityListener() {
    const modal = this.element.shadowRoot.querySelector(".modal");

    if (modal) {
      const self = this;
      // Callback function to execute when mutations are observed
      const callback = function (mutationsList, observer) {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
          ) {
            // we need to manually reset the actions when the command palette closes
            if (!modal.classList.contains("visible"))
              self.setActions(self.availableActions());
          }
        }
      };

      // Create an instance of the MutationObserver with the callback
      const observer = new MutationObserver(callback);

      // Configuration object for the observer (observe attribute changes)
      const config = { attributes: true, attributeFilter: ["class"] };

      // Start observing the target node for configured mutations
      observer.observe(modal, config);
    } else console.log("The modal div was not found inside the shadow DOM.");
  }

  async handleCommandPaletteChange(event) {
    // console.log("ninja on change", event.detail);
    if (!event.detail.search) {
      this.element.data = this.availableActions();
      return;
    }

    const response = await post(
      `/account/teams/${this.teamIdValue}/command_palette_search?q=${event.detail.search}`,
      { responseKind: "json" }
    );

    let searchResults = [];
    if (response.ok) {
      let objects = await response.json;
      objects = objects.map((object) => {
        object["handler"] = () => {
          Turbo.visit(object["path"]);
        };

        return object;
      });

      searchResults = objects;
    }

    const fuse = new Fuse([...searchResults, ...this.availableActions()], {
      ignoreLocation: true,
      ignoreFieldNorm: true,
      keys: ["title", "keywords", "section"],
    });

    const actions = fuse
      .search(event.detail.search)
      .map((result) => result.item);

    this.setActions(actions);
  }

  setActions(actions) {
    this.element.data = actions;
  }

  connect() {
    this.addListeners();
    this.setTheme();
    this.originalRolesValue = this.rolesValue;
    this.originalMembershipIdValue = this.membershipIdValue;
    this.originalUserIdValue = this.originalUserIdValue;
    this.setActions(this.availableActions());
  }

  rolesValueChanged(current, prev) {
    // we don't want people to change their roles, obv
    if (this.originalRolesValue && this.originalRolesValue !== current)
      this.rolesValue = this.originalRolesValue;
  }

  userIdValueChanged(current, prev) {
    // we don't want people to change their userId, obv
    if (this.originalUserIdValue && this.originalUserIdValue !== current)
      this.userIdValue = this.originalUserIdValue;
  }

  membershipIdValueChanged(current, prev) {
    // we don't want people to change their membershipId, obv
    if (
      this.originalMembershipIdValue &&
      this.originalMembershipIdValue !== current
    )
      this.membershipIdValue = this.originalMembershipIdValue;
  }

  setTheme(
    isDarkMode = window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    isDarkMode
      ? this.element.classList.add("dark")
      : this.element.classList.remove("dark");
  }

  availableActions() {
    if (this.rolesValue.includes("admin")) return this.adminActions();
    else if (this.rolesValue.includes("developer"))
      return this.developerActions();
    else return this.defaultActions();
  }

  defaultActions() {
    return [
      {
        id: "Home",
        title: "Go to Home",
        mdIcon: "home",
        hotkey: "alt+h,⌥+h",
        section: "Navigation",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}`);
        },
      },
      {
        id: "Open Sidebar Menu",
        title: "Open Sidebar Menu",
        mdIcon: "menu",
        section: "Navigation",
        handler: () => {
          document.querySelector("button.mobile-menu-trigger").click();
        },
      },
      {
        id: "My Profile",
        title: "View My Profile",
        section: "Me",
        mdIcon: "person",
        hotkey: "alt+u,⌥+u",
        handler: () => {
          Turbo.visit(`/account/memberships/${this.membershipIdValue}`);
        },
      },
      {
        id: "My Account Settings",
        title: "View My Account Settings",
        section: "Me",
        mdIcon: "manage_accounts",
        hotkey: "alt+s,⌥+s",
        handler: () => {
          Turbo.visit(`/account/users/${this.userIdValue}/edit`);
        },
      },
      {
        id: "Log Out",
        title: "Log Out of Salesform",
        section: "Me",
        mdIcon: "logout",
        handler: () => {
          document
            .querySelector(
              "form[action='/users/sign_out'] > button[type='submit']"
            )
            .click();
        },
      },
      {
        id: "Forms",
        section: "Forms",
        title: "View Forms",
        mdIcon: "description",
        hotkey: "alt+f,⌥+f",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/salesforms`);
        },
      },
      {
        id: "Lineups",
        section: "Lineups",
        title: "View Lineups",
        mdIcon: "group_work",
        hotkey: "alt+q,⌥+q",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/lineups`);
        },
      },
      {
        id: "Leads",
        section: "Leads",
        title: "View Leads",
        mdIcon: "contacts",
        hotkey: "alt+l,⌥+l",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/prospects`);
        },
      },
      {
        id: "Create Lead",
        section: "Leads",
        title: "Create Lead",
        mdIcon: "contacts",
        hotkey: "alt+shift+l,⌥+shift+l",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/prospects/new`);
        },
      },
      {
        id: "Meetings",
        section: "Meetings",
        title: "View Meetings",
        mdIcon: "calendar_month",
        hotkey: "alt+m,⌥+m",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/bookings`);
        },
      },
      {
        id: "Create Meeting",
        section: "Meetings",
        title: "Create Meeting",
        mdIcon: "calendar_month",
        hotkey: "alt+shift+m,⌥+shift+m",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/bookings/new`);
        },
      },
      {
        id: "Scheduling Links",
        section: "Scheduling Links",
        title: "View Scheduling Links",
        mdIcon: "link",
        hotkey: "alt+n,⌥+n",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/event_types`);
        },
      },
      {
        id: "Create Scheduling Link",
        section: "Scheduling Links",
        title: "Create Scheduling Link",
        mdIcon: "link",
        hotkey: "alt+shift+n,⌥+shift+n",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/event_types/new`);
        },
      },
      {
        id: "CRM Triggers",
        section: "CRM Triggers",
        title: "View CRM Triggers",
        mdIcon: "satellite_alt",
        hotkey: "alt+x,⌥+x",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/crm_triggers`);
        },
      },
      {
        id: "Team Members",
        section: "Team",
        title: "View Team Members",
        mdIcon: "groups",
        hotkey: "alt+t,⌥+t",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/memberships`);
        },
      },
    ];
  }

  developerActions() {
    return [
      ...this.defaultActions(),
      {
        id: "Webhooks",
        section: "Developers",
        title: "View Webhooks",
        mdIcon: "webhook",
        hotkey: "alt+w,⌥+w",
        handler: () => {
          Turbo.visit(
            `/account/teams/${this.teamIdValue}/webhooks/outgoing/endpoints`
          );
        },
      },
      {
        id: "Create Webhook",
        section: "Developers",
        title: "Create Webhooks",
        // mdIcon: "webhook",
        hotkey: "alt+shift+w,⌥+shift+w",
        handler: () => {
          Turbo.visit(
            `/account/teams/${this.teamIdValue}/webhooks/outgoing/endpoints`
          );
        },
      },
    ];
  }

  adminActions() {
    return [
      ...this.defaultActions(),
      {
        id: "Create Form",
        section: "Forms",
        title: "Create Form",
        mdIcon: "description",
        hotkey: "alt+shift+f,⌥+shift+f",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/salesforms/sync`);
        },
      },
      {
        id: "Create CRM Trigger",
        section: "CRM Triggers",
        title: "Create CRM Trigger",
        mdIcon: "satellite_alt",
        hotkey: "alt+shift+x,⌥+shift+x",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/crm_triggers/new`);
        },
      },
      {
        id: "Create Lineup",
        section: "Lineups",
        title: "Create Lineup",
        mdIcon: "group_work",
        hotkey: "alt+shift+q,⌥+shift+q",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/lineups/new`);
        },
      },
      {
        id: "Team Settings",
        section: "Team",
        title: "View Team Settings",
        mdIcon: "settings",
        hotkey: "alt+r,⌥+r",
        handler: () => {
          Turbo.visit(`/account/teams/${this.teamIdValue}/edit`);
        },
      },
      {
        id: "Webhooks",
        section: "Developers",
        title: "View Webhooks",
        mdIcon: "webhook",
        hotkey: "alt+w,⌥+w",
        handler: () => {
          Turbo.visit(
            `/account/teams/${this.teamIdValue}/webhooks/outgoing/endpoints`
          );
        },
      },
      {
        id: "Create Webhook",
        section: "Developers",
        title: "Create Webhook",
        mdIcon: "webhook",
        hotkey: "alt+shift+w,⌥+shift+w",
        handler: () => {
          Turbo.visit(
            `/account/teams/${this.teamIdValue}/webhooks/outgoing/endpoints/new`
          );
        },
      },
    ];
  }
}
