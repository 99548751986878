import CRMLinkController from "../../../../xkit/crm_link_controller";

export default class extends CRMLinkController {
  async confirmPostProcessings() {
    document.querySelector("#confirm_post_processings_form").requestSubmit();
  }

  async show(event) {
    const customEvent = new CustomEvent(event.type, {
      detail: { event, afterFinish: this.confirmPostProcessings },
    });
    await super.show(customEvent);
  }
}
