import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";

export default (hit, { html, components }) => {
  const {
    obfuscated_id,
    start_at_timestamp,
    end_at_timestamp,
    attendee_memberships,
    guest_prospects,
  } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/bookings/${obfuscated_id}"
    >
      <div class="space-y-3">
        <div class="font-bold">
          ${components.Highlight({
            attribute: "topic",
            hit,
          })}
        </div>
        <div class="text-2xs">
          <div class="inline-block w-20">Starts At:</div>
          ${format(fromUnixTime(start_at_timestamp), "MMM dd, yyyy @ h:mm a")}
        </div>
        <div class="text-2xs">
          <div class="inline-block w-20">Ends At:</div>
          ${format(fromUnixTime(end_at_timestamp), "MMM dd, yyyy @ h:mm a")}
        </div>
        <div class="text-2xs border-t py-1">
          <div class="inline-block w-20 align-top">Guests:</div>
          <div class="inline-block">
            ${guest_prospects.map((prospect, i) => {
              return html`<div>
                ${prospect.full_name
                  ? html`${components.Highlight({
                        attribute: `guest_prospects.${i}.full_name`,
                        hit,
                      })}${" "}
                      (<span class="italic"
                        >${components.Highlight({
                          attribute: `guest_prospects.${i}.email`,
                          hit,
                        })}</span
                      >)`
                  : html`${components.Highlight({
                      attribute: `guest_prospects.${i}.email`,
                      hit,
                    })}`}
              </div>`;
            })}
          </div>
        </div>
        <div class="text-2xs border-t py-1">
          <div class="inline-block w-20 align-top">Attendees:</div>
          <div class="inline-block">
            ${attendee_memberships.map((_membership, i) => {
              return html`<div>
                ${components.Highlight({
                  attribute: `attendee_memberships.${i}.full_name`,
                  hit,
                })}${" "}
                (<span class="italic"
                  >${components.Highlight({
                    attribute: `attendee_memberships.${i}.email`,
                    hit,
                  })}</span
                >)
              </div>`;
            })}
          </div>
        </div>
      </div>
    </a>
  `;
};
