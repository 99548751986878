import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  connect() {
    const self = this;
    this.submittingForGroupHost = false;

    this.element.addEventListener("submit", (event) => {
      event.preventDefault();
      if (self.submittingForGroupHost) {
        const formFields = event.target.querySelectorAll(
          '[name^="lineups_membership"]'
        );

        formFields.forEach((formField) => {
          formField.disabled = true;
        });

        const groupHostField = event.target.querySelector(
          '[name="lineups_membership[lineup_attributes][group_host_id]"]'
        );

        groupHostField.disabled = false;
      }
      navigator.submitForm(event.target)
    });
  }

  submitFormForGroupHost() {
    this.submittingForGroupHost = true;
    this.element.requestSubmit();
  }
}
