import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "destinationTypeSelect",
    "membershipSelect",
    "membershipSelectWrapper",
    "lineupSelect",
    "lineupSelectWrapper",
    "eventTypeSelect",
    "eventTypeSelectWrapper",
    "urlInput",
    "urlInputWrapper",
    "crmOwnershipConfigWrapper",
  ];

  connect() {
    const self = this;
    this.form = this.element;
    this.form
      .querySelectorAll('input[name="routers_rule[pull_owner_from_crm]"]')
      .forEach((input) => {
        input.addEventListener("change", () => self.form.requestSubmit());
      });
  }

  handleDestinationTypeChange({ detail: { event } }) {
    const destinationType = event.target.value;

    switch (destinationType) {
      case "membership":
        this.toggleLineupSelect(false);
        this.toggleUrlInput(false);
        this.toggleMembershipSelect(true);
        this.toggleEventTypeSelect(true);
        this.toggleCrmOwnershipConfig(true);
        return;
      case "lineup":
        this.toggleMembershipSelect(false);
        this.toggleUrlInput(false);
        this.toggleLineupSelect(true);
        this.toggleEventTypeSelect(true);
        this.toggleCrmOwnershipConfig(true);

        return;
      case "redirect":
        this.toggleLineupSelect(false);
        this.toggleMembershipSelect(false);
        this.toggleEventTypeSelect(false);
        this.toggleCrmOwnershipConfig(false);
        this.toggleUrlInput(true);

        return;
      case "none":
        this.clearMembershipSelect();
        this.clearLineupSelect();
        this.clearEventTypeSelect();
        this.clearUrlInput();
        this.form.requestSubmit();
      default:
        this.toggleMembershipSelect(false);
        this.toggleLineupSelect(false);
        this.toggleUrlInput(false);
        this.toggleCrmOwnershipConfig(false);
        this.toggleEventTypeSelect(false);
    }
  }

  handleLineupChange() {
    this.clearMembershipSelect();
    this.form.requestSubmit();
  }

  handleMembershipChange() {
    this.clearLineupSelect();
    this.form.requestSubmit();
  }

  handleEventTypeChange() {
    this.form.requestSubmit();
  }

  clearLineupSelect() {
    if (!this.hasLineupSelectTarget) return;

    if (!!$(this.lineupSelectTarget).val()) $(this.lineupSelectTarget).val("");
  }

  clearEventTypeSelect() {
    if (!this.hasEventTypeSelectTarget) return;

    if (!!$(this.eventTypeSelectTarget).val())
      $(this.eventTypeSelectTarget).val("");
  }

  clearMembershipSelect() {
    if (!this.hasMembershipSelectTarget) return;

    if (!!$(this.membershipSelectTarget).val())
      $(this.membershipSelectTarget).val("");
  }

  clearUrlInput() {
    if (!this.hasUrlInputTarget) return;

    if (!!this.urlInputTarget.value) this.urlInputTarget.value = null;
  }

  toggleLineupSelect(show) {
    if (!this.hasLineupSelectWrapperTarget) return;

    if (show) this.lineupSelectWrapperTarget.classList.remove("hidden");
    else this.lineupSelectWrapperTarget.classList.add("hidden");
  }

  toggleEventTypeSelect(show) {
    if (!this.hasEventTypeSelectWrapperTarget) return;

    if (show) this.eventTypeSelectWrapperTarget.classList.remove("hidden");
    else this.eventTypeSelectWrapperTarget.classList.add("hidden");
  }

  toggleMembershipSelect(show) {
    if (!this.hasMembershipSelectWrapperTarget) return;

    if (show) this.membershipSelectWrapperTarget.classList.remove("hidden");
    else this.membershipSelectWrapperTarget.classList.add("hidden");
  }

  toggleUrlInput(show) {
    if (!this.hasUrlInputWrapperTarget) return;

    if (show) this.urlInputWrapperTarget.classList.remove("hidden");
    else {
      this.urlInputWrapperTarget.classList.add("hidden");
    }
  }

  toggleCrmOwnershipConfig(show) {
    if (!this.hasCrmOwnershipConfigWrapperTarget) return;

    if (show) this.crmOwnershipConfigWrapperTarget.classList.remove("hidden");
    else this.crmOwnershipConfigWrapperTarget.classList.add("hidden");
  }
}
