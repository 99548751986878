import { get } from "@rails/request.js";
import consumer from "../../../../channels/consumer";
import DisclosureController from "../../../disclosure_controller";
import { capitalize } from "lodash";

export default class extends DisclosureController {
  static values = { salesformId: String };

  baseError(message) {
    return `
           <div class="rounded-md bg-red-light border border-red py-4 px-5 mb-3">
             <h3 class="text-sm text-red-darker font-light">
               ${message}
             </h3>
           </div>
           `;
  }

  fieldError(field, message) {
    return `<p class="mt-1.5 text-xs text-red">${field
      .split("_")
      .map(capitalize)
      .join(" ")} ${message}</p>`;
  }

  insertBaseErrorMessage(form, message) {
    form.insertAdjacentHTML("afterbegin", this.baseError(message));
  }

  insertFieldErrorMessage(form, field, message) {
    const input = form.querySelector(`input[id="salesform_${field}"]`);
    if (input) {
      input.className =
        "block w-full rounded-md shadow-sm font-light text-sm pr-10 border-red text-red-darker \
        placeholder-red focus:outline-none focus:ring-red focus:border-red dark:bg-sealBlue-300 \
        dark:text-sealBlue-900";
      input.insertAdjacentHTML("afterend", this.fieldError(field, message));
    }
  }

  setNotice(textContent, color) {
    const notice = document.querySelector("div.notice");
    const noticeText = notice.querySelector("h3");
    if (notice) {
      notice.classList.replace("bg-yellow-light", `bg-${color}-light`);
      notice.classList.replace("border-yellow", `border-${color}`);
      noticeText.classList.replace(
        "text-yellow-darker",
        `text-${color}-darker`
      );
      noticeText.textContent = textContent;
      setTimeout(() => notice.remove(), 10000);
    }
  }

  subscribeToChannel() {
    if (!this.hasSalesformIdValue) return;
    const self = this;

    consumer.subscriptions.create(
      {
        channel: "Salesforms::SyncChannel",
        salesform_id: self.salesformIdValue,
      },
      {
        received({ sync_state, errors }) {
          switch (sync_state) {
            case "working":
              self.stateValue = "loading";
              return;
            case "success":
              self.stateValue = "success";
              self.setNotice("Form has been successfully created!", "green");
              get(`/account/salesforms/${self.salesformIdValue}`, {
                responseKind: "turbo-stream",
              });

              return;
            case "error":
              self.setNotice("There was an error creating the form.", "red");

              Object.entries(errors).forEach(([field, msg]) => {
                switch (field) {
                  case "base":
                    self.insertBaseErrorMessage(self.form, msg);
                    return;
                  default:
                    self.insertFieldErrorMessage(self.form, field, msg);
                    return;
                }
              });
            default:
              self.stateValue = "default";
          }
        },
      }
    );
  }

  connect() {
    super.connect();
    this.form = this.element.querySelector("form");
    this.subscribeToChannel();
  }
}
