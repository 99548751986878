import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["command-palette"];

  commandPaletteOutletConnected(outlet, commandPalette) {
    this.element.addEventListener("click", () => {
      commandPalette.open()
    });
  }
}
