import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import { sortBy } from "lodash";

export default (hit, { html, components }) => {
  const {
    obfuscated_id,
    timestamp,
    executed_rules,
    destination_membership,
    destination_lineup,
    destination_event_type,
    destination_url,
  } = hit;

  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/routers/execution_logs/${obfuscated_id}"
    >
      <div class="space-y-3">
        <fieldset class="p-2">
          <legend class="text-2xs font-medium">Executed Rules</legend>
          <div class="space-y-2">
            ${sortBy(executed_rules, ["sort_order"]).map((rule) => {
              return html`<div
                class="border rounded px-2 w-max
                ${rule.pass
                  ? "border-green bg-green-light text-green-darker"
                  : "bg-yellow-light border-yellow-dark text-yellow-dark"}"
              >
                ${rule.name}
              </div>`;
            })}
          </div>
        </fieldset>
        <div class="text-2xs flex flex-row">
          <div class="inline-block">Lead Email:</div>
          <div class="inline-block ml-3">
            ${html`${components.Highlight({
              attribute: "data.email",
              hit,
            })}`}
          </div>
        </div>
        ${!destination_url
          ? html``
          : html`<div class="text-2xs flex flex-row">
        <div class="inline-block">Redirected to:</div>
        <div class="inline-block  ml-3">
          ${html`${components.Highlight({
            attribute: "destination_url",
            hit,
          })}`}
        </div>
      </div>`}
        ${!destination_membership
          ? html``
          : html`<div class="text-2xs flex flex-row">
        <div class="inline-block">Team Member:</div>
        <div class="inline-block ml-3">
          ${html`${components.Highlight({
            attribute: "destination_membership.full_name",
            hit,
          })}`}
        </div>
      </div>`}
        ${!destination_lineup
          ? html``
          : html`<div class="text-2xs flex flex-row">
      <div class="inline-block ">Lineup:</div>
      <div class="inline-block  ml-3">
        ${html`${components.Highlight({
          attribute: "destination_lineup.name",
          hit,
        })}`}
      </div>
    </div>`}
        ${!destination_event_type
          ? html``
          : html`<div class="text-2xs flex flex-row">
  <div class="inline-block ">Prompted to Schedule:</div>
  <div class="inline-block ml-3">
    ${html`${components.Highlight({
      attribute: "destination_event_type.title",
      hit,
    })}`}
  </div>
</div>`}
        <div class="text-2xs flex flex-row">
          <div class="inline-block">Timestamp:</div>
          <div class="inline-block  ml-3">${format(fromUnixTime(timestamp), "MMM dd, yyyy @ h:mm:ss a")}</div>
        </div>
      </div>
    </a>
  `;
};
