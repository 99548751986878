import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "btFileField"];

  connect() {
    if (this.hasBtFileFieldTarget) {
      this.element.addEventListener("fields--file-field:file-selected", () => {
        if (this.hasFormTarget) this.formTarget.requestSubmit();
      });
    }
  }

  disconnect() {}
}
