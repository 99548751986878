import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["superSelect", "inspectorWrapper"];
  static values = {};

  connect() {
    if (this.hasInspectorWrapperTarget) {
      this.highlight = this.inspectorWrapperTarget.querySelector(".highlight");
      this.styleHighlight();
    }
  }

  handleSelectChange() {
    this.element.requestSubmit();
  }

  styleHighlight() {
    this.highlight.style.padding = "0.5rem";
    this.highlight.style.height = "400px";
    this.highlight.style.overflowY = "auto";
  }

  clearSampleData(_event) {
    if (!this.hasSuperSelectTarget) return;
    $(this.superSelectTarget).val("");
    $(this.superSelectTarget).trigger("change");
  }
}
