import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = [
    "field",
    "fieldHumanizer",
    "operator",
    "operatorHumanizer",
    "valueSelect",
    "valueInput",
    // "valueHumanizer"
  ];
  static values = { valueInputMode: { type: String, default: "text" } };

  submitForm(event) {
    if (event.target.disabled) return;
    this.element.requestSubmit();
  }

  connect() {}

  fieldTargetConnected(element) {
    this.fieldTargetValue = $(element).val();
    this.fieldOptionLabel = $(element).find(":selected")[0].label;
    this.fieldOptionType = $(element).find(":selected")[0].dataset.optionType;

    if (
      this.hasFieldHumanizerTarget &&
      this.fieldOptionLabel &&
      this.fieldOptionType
    ) {
      if (this.fieldTargetValue.includes(".trackers."))
        this.fieldHumanizerTarget.textContent = `Tracker ${this.fieldOptionLabel} (${this.fieldOptionType})`;
      else
        this.fieldHumanizerTarget.textContent = `${this.fieldOptionLabel} (${this.fieldOptionType})`;
    }
  }

  operatorTargetConnected(element) {
    this.operatorTargetValue = $(element).val();
    this.operatorOptionLabel = $(element).find(":selected")[0].label;
    this.operatorOptionType =
      $(element).find(":selected")[0].dataset.optionType;

    if (this.hasOperatorHumanizerTarget && this.operatorOptionLabel) {
      this.operatorHumanizerTarget.textContent =
        this.operatorOptionLabel.replace(/\(.*?\)/g, "");
    }
  }

  valueInputTargetConnected(element) {
    const controller = this;

    element.addEventListener(
      "keyup",
      debounce(this.submitForm.bind(controller), 750)
    );
  }

  handleFieldChange({ detail: { event } }) {
    let prevFieldOptionType;
    if (this.fieldOptionType) prevFieldOptionType = this.fieldOptionType;

    this.fieldOptionType = optionType(event.target) || "*";

    this.fieldOptionLabel = $(event.target).find(":selected")[0].label;
    this.fieldTargetValue = select2Value(event.target);

    if (
      !!prevFieldOptionType &&
      (prevFieldOptionType !== this.fieldOptionType ||
        !this.fieldOptionType !== "*")
    )
      this.valueInputTargets.forEach((input) => (input.value = ""));
    if (
      !!this.operatorOptionType &&
      (this.fieldOptionType !== this.operatorOptionType ||
        this.operatorOptionType !== "*")
    )
      $(this.operatorTarget).val(null).trigger("change");
    if (this.valueInputModeValue === "select")
      $(this.valueSelectTarget).val(null).trigger("change");

    this.element.requestSubmit();
  }

  handleOperatorChange({ detail: { event } }) {
    this.operatorOptionType = optionType(event.target) || "*";
    this.operatorOptionLabel = $(event.target).find(":selected")[0].label;
    this.operatorTargetValue = select2Value(event.target);

    this.element.requestSubmit();
  }
}

function optionType(select2Target) {
  return $(select2Target).select2("data")[0].element.dataset.optionType;
}

function select2Value(select2Target) {
  return $(select2Target).select2("data")[0].id;
}
