import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];
  static values = { open: { default: false, type: Boolean } };

  connect() {
    this.executionLogId = this.element.closest('tr').dataset.id;
    this.dataRow = document.querySelector(
      `#routers_execution_log_${this.executionLogId}_data_row`
    );
    this.toggledClass = "hidden";
    this.tdElementBgClasses = ["bg-neutral-50", "dark:bg-sealBlue-300"];
  }

  toggleTargetConnected(toggleTarget) {
    this.tdElement = toggleTarget.parentElement;
  }

  toggle(event) {
    event.preventDefault();

    this.openValue = !this.openValue;
  }

  hide(event) {
    event.preventDefault();

    this.openValue = false;
  }

  show(event) {
    event.preventDefault();

    this.openValue = true;
  }

  openValueChanged(open, _prevValue) {
    if (!this.toggledClass || !this.dataRow) {
      return;
    }

    if (open) this.dataRow.classList.remove(this.toggledClass);
    else this.dataRow.classList.add(this.toggledClass);

    this.dataRow.classList.contains(this.toggledClass)
      ? this.tdElement.classList.remove(...this.tdElementBgClasses)
      : this.tdElement.classList.add(...this.tdElementBgClasses);

    this.toggleTarget.textContent = this.dataRow.classList.contains(
      this.toggledClass
    )
      ? "Show"
      : "Hide";
  }
}
