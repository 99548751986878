import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.form = this.element;

    this.element
      .querySelectorAll(`input[type='radio'][name='routers_rule[action]']`)
      .forEach((input) =>
        input.addEventListener("change", () => {
          this.form.requestSubmit();
        })
      );
  }
}
