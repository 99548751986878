import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { teamId: String };

  subscribeToChannel() {
    const self = this;
    consumer.subscriptions.create(
      {
        channel: "Memberships::IndexChannel",
        team_id: self.teamIdValue,
      },
      {
        // initialized() {
        //   console.log("initialized subscription");
        // },
        // connected() {
        //   console.log(
        //     `subscribed to channel: memberships_team_${self.teamIdValue}`
        //   );
        // },
        received() {
          get(`/account/teams/${self.teamIdValue}/memberships`, { responseKind: "turbo-stream" });
        },
      }
    );
  }

  connect() {
    if (!this.hasTeamIdValue) return;
    this.subscribeToChannel();
  }
}
